@import "@progress/kendo-theme-classic";

.k-input.k-multiselect {
  border: 1px solid #ccc !important;
  border-radius: 0;
  min-height: 32px;

  &.k-focus {
    outline: 5px auto #49bc1c !important;
  }

  input.k-input-inner {
    padding: 4px 8px;
    width: 100%;
    border: 0;
  }

  .k-chip-solid-base {
    border-radius: 0;
    border: 1px solid #ccc;
    background-color: #fff;

    &.k-hover {
      background-color: #CF4B13;
      border-color: #CF4B13;
      color: #fff;
    }
  }
}
.k-list-item,
.k-list-optionlabel {
  border-bottom:1px solid #fff;
}
.k-list-item.k-selected, .k-selected.k-list-optionlabel {
  background-color: #CF4B13;
  box-shadow: none;

  &.k-hover {
    background-color: #993911;
  }
}